import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SessionStorageService } from './session_storage';


@Injectable({
  providedIn: 'root'
})
export class MsGraphService {
  userName!: string;

  
  constructor(private http: HttpClient, private session:SessionStorageService) {   }

  private apiRoot = `${environment.msGraphUrl}`;


  getUserPhoto(): Observable<any> {
    const endPoint = this.apiRoot + 'me/photo/$value';
    const headers = new HttpHeaders().set('Content-Type', 'images/jpeg').set('Authorization', 'Bearer '+this.session.getItem('token'));
    return this.http.get(endPoint, { headers, responseType: "arraybuffer" });
  }

  getUserPhotoByEmail(mail: string): Observable<any> {
    const endPoint = this.apiRoot + 'users/'+mail+'/photo/$value';
    const headers = new HttpHeaders().set('Content-Type', 'images/jpeg').set('Authorization', 'Bearer '+this.session.getItem('token'));
    return this.http.get(endPoint, { headers, responseType: "arraybuffer" });
  }

  getUserByEmail(mail: string): Observable<any> {
    const graphCondition = `users/${mail}`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'Bearer '+this.session.getItem('token'));
    const endPoint = this.apiRoot + graphCondition;
    return this.http.get(endPoint, {headers: headers});
    // return this.http.get<any>(endPoint,{headers});
  }
  getAADGroupId(groupName: string): Observable<any> {
    const graphCondition = `groups?$filter=startswith(displayName,'${groupName}')`;
    const endPoint = this.apiRoot + graphCondition;
    return this.http.get<any>(endPoint);
  }

  getGraphUsers(givenName: string): Observable<any> {
    const graphCondition = `users?$filter=startswith(givenName,'${givenName}') OR startswith(surname,'${givenName}')&$count=true`;
    const endPoint = this.apiRoot + graphCondition;
    return this.http.get<any>(endPoint);
  }
  
  getAADGroupMembersFromGraph(groupId: string): Observable<any> {
    const graphCondition = `groups/${groupId}/members`
    const endPoint = this.apiRoot + graphCondition;
    return this.http.get<any>(endPoint);
  }

  callAccessTokenAPI(authService:any){      
    let scopes = environment.calConfig.oidcScopes;     
    return authService.getAADToken(scopes)
        .then(this.getAccessToken)
        .catch((error: any) => console.log(`An error occurred ${error}`)); 
  }
  getAccessToken(token: any){ 
    sessionStorage.setItem('token', token.accessToken.toString())    
    return token.accessToken;
    
  }
  
  searchUser(givenName:string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'Bearer '+this.session.getItem('token'));   
    let value =`$filter=startswith(givenName,\'${givenName}\') OR startswith(surname,'${givenName}')&$count=true`;
    const endPoint = `${environment.msGraphUrl}`  + `users/?${value}`;
    return this.http.get(endPoint, {headers: headers})
  }

  getGraphUsersByNameOrEmail(value: string): Observable<any> {
    if(value.includes('@chevron.com')){
      const graphCondition = `users?${value}`;
      const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'Bearer '+this.session.getItem('token'));
      const endPoint = this.apiRoot + graphCondition;
      return this.http.get(endPoint, {headers: headers});
    }else{
      const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'Bearer '+this.session.getItem('token'));   
      let path =`$filter=startswith(givenName,\'${value}\') OR startswith(surname,'${value}')&$count=true`;
      const endPoint = `${environment.msGraphUrl}`  + `users/?${path}`;
      return this.http.get(endPoint, {headers: headers})
    }
    
    
  }
  
}