import { Injectable} from '@angular/core';
import { SessionStorageService } from './session_storage';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { UserDetail, UserDetails } from '../interfaces/User';
import { MsGraphService } from './ms-graph';
import { LFDAPIService } from './api';
import { Role } from 'src/app/constants/constants';

@Injectable ({
    providedIn: 'root'
})
export class DataService {
    
    proposalId: any;
    requestId: any;
    proposalType:any;
    currentUser: any;
    currentUserPhoto: any;
    statuses:any;
    aqEntitySelectionForm: any;
    aqProposedSlatesform: any;
    aqProposalOverviewForm: any;
    assignmentForm: any;
    requestedForUser: UserDetails[] = [];
    userDetails: UserDetail={
      personId:0,
      name:"",
      email:"",
      jobTitle:"",
      LawGroupId:0,
      role:"",
      address:"",
      mobile:"",
      telephone:""
    };
    userData:any;
    currentUserProfile!: ICvxClaimsPrincipal;
    constructor(private session:SessionStorageService, private apiService: LFDAPIService, private authService:CalAngularService, private graph: MsGraphService){
      

    }
    getAccessToken(){
        this.authService.getAADToken().subscribe(result =>{
            this.session.setItem('token', JSON.parse(JSON.stringify(result)));
          });
    }
     
    higlightCurrentMenu(selectedMenuName:string){
      let isAdminExist=false;
      $('#site-specific-nav li a').each(function(this,index){
         $(this).removeClass('selectedmenu');
         $(this).removeAttr('style');
         if($(this).text()==selectedMenuName){
          $(this).addClass('selectedmenu');
          $(this).attr("style","color:#fff!important;");
         }
         if($(this).text()=='admin'){
          isAdminExist=true; 
         }
      });
      let userdata=this.session.getItem('userdetail') as UserDetail;
      if(userdata?.role==Role.Admin && isAdminExist==false){
        $('#site-specific-nav ul').append('<li><a ng-reflect-router-link="/admin" href="/admin" style="color:#fff!important;">admin</a></li>');
        //selected admin menu
        $('#site-specific-nav li a').each(function(this,index){
          $(this).removeClass('selectedmenu');
          $(this).removeAttr('style');
          if($(this).text()==selectedMenuName){
           $(this).addClass('selectedmenu');
           $(this).attr("style","color:#fff!important;");
          }
       });
      }
      
    }
    getUserDetailRequest(){
      return new Promise<boolean>((resolved)=>{
      let userdata=this.session.getItem('userdetail') as UserDetail;
      if(!userdata){
      this.currentUserProfile = this.authService.cvxClaimsPrincipal;
      this.session.setItem("useremail",this.currentUserProfile.email);
      this.apiService.getUserDetailByEmailId(this.currentUserProfile.email).subscribe(result=>{
        if(result.status === 200 && result.body){
          this.userData=result.body;
          this.userDetails=this.userData.userDetails as UserDetail;
          this.session.setItem("userdetail",this.userDetails);
          resolved(true);
        }
        else{

        }
       });
      }
    });
    }
    
    getAuthorizationToken():Promise<boolean> {
      return new Promise<boolean>((resolved)=>{
        this.authService.getAADToken().subscribe(result => {
          this.session.setItem('token', JSON.parse(JSON.stringify(result)));
          resolved(true);
        });
      });
    }

    async getRequestId(tempProposalId: any) {
      let arrParts = tempProposalId.split("-");
      return arrParts[2];
    }
    

    async searchUser(value: string): Promise<UserDetails[]> {
        if(value) {
            this.requestedForUser = [];
            const result = new Promise<boolean>((resolved) =>{
                if(value.endsWith('@chevron.com')){
                    this.graph.getUserByEmail(value).subscribe(event =>{ 
                        if(this.requestedForUser.filter(e=>e.mail === event.mail).length === 0){
                            this.requestedForUser.push({
                                displayName: event.displayName,
                                mail:event.mail,
                                fullName:`${event.givenName} ${event.surname}`
                            });
                            resolved(true);
                        }
                    });
                  }else{
                    this.graph.searchUser(value).subscribe(arr => {
                      arr['value'].forEach((element: any) => {
                        if(element.mail){
                          if(element.mail.includes('chevron.com')){
                            if(this.requestedForUser.filter(e=>e.mail === element.mail).length === 0){
                                this.requestedForUser.push({
                                    displayName: element.displayName,
                                    mail:element.mail,
                                    fullName:`${element.givenName} ${element.surname}`
                                  });
                                }
                            }
                              
                          resolved(true);
                        }
                      });
                    });
                  }
            });
            if(await result){
                return [... new Set(this.requestedForUser)]; 
            }else{
                return this.requestedForUser;
            }
        }else{
            return this.requestedForUser; 
        }
        
        
        
}
}